import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Mode} from '../models/mode.model';
import {State} from '../models/state.model';
import {MessageHandlerService} from './messageHandler.service';
import {environment} from '../../../environments/environment';
import {BsFormAutocompleteOption} from 'bs-components';

@Injectable({
  providedIn: 'root',
})
export class ModeService {
  private readonly _modes$: BehaviorSubject<Mode[]> = new BehaviorSubject<Mode[]>([]);

  private readonly serviceUrl: string = environment.baseUrl + '/modes';

  constructor(
    private readonly http: HttpClient,
    private readonly messageService: MessageHandlerService,
    private readonly translate: TranslateService
  ) {
  }

  // --------------------------------------------- HTTP FUNCTIONS ---------------------------------------------------------- //


  public fetchAllModes(): Observable<Mode[]> {
    return this.http.get<Mode[]>(this.serviceUrl).pipe(
      tap((modes) => {
        const sortedModes: Mode[] = modes.map((m) => {
          m.states.sort((a: any, b: any) => {
            if (a.place > b.place) {
              return 1;
            } else if (a.place < b.place) {
              return -1;
            } else {
              return 0;
            }
          });
          return m;
        });
        this._modes$.next(sortedModes);
      }),
      catchError(
        this.messageService.handleError<Mode[]>(
          this.translate.instant('LOGS.ACTIVITIES.GET_ALL_FAILURE')
        )
      )
    );
  }

  public editMode(id: number, body: EditModeBody): Observable<Mode> {
    return this.http.put<Mode>(this.serviceUrl + '/' + id, body).pipe(
      tap((mode) => {
        const newModes = this.getModes().filter((m) => mode.id != m.id);
        newModes.push(mode);
        newModes.sort((a: Mode, b: Mode) => {
          if (a.order > b.order) {
            return 1;
          } else if (a.order < b.order) {
            return -1;
          } else {
            return 0;
          }
        });
        this._modes$.next(newModes);

        this.messageService.log(
          'validation',
          this.translate.instant('LOGS.ACTIVITIES.UPDATE_SUCCESS'),
          1,
          true
        );
      }),
      catchError(
        this.messageService.handleError<Mode>(
          this.translate.instant('LOGS.ACTIVITIES.UPDATE_FAILURE')
        )
      )
    );
  }

  public setNewModeState(modeId: number, state: State) {
    const modes = this.getModes();
    const mode = modes.find((m) => m.id == modeId);
    const index = mode.states.indexOf(
      mode.states.find((s) => s.id == state.id)
    );
    mode.states = mode.states.filter((s) => s.id != state.id);
    mode.states.splice(index, 0, state);
    const newModes = modes.filter((m) => mode.id != m.id);
    newModes.push(mode);
    newModes.sort((a: Mode, b: Mode) => {
      if (a.order > b.order) {
        return 1;
      } else if (a.order < b.order) {
        return -1;
      } else {
        return 0;
      }
    });
    this._modes$.next(newModes);
  }

  // --------------------------------------------- ACCESSORS ---------------------------------------------------------- //

  public getModes(): Mode[] {
    return this._modes$.getValue();
  }

  public getModeOptions(): BsFormAutocompleteOption[] {
    return this.getModes()
      .map((mode) => {
        return this.toSelectOption(mode);
      });
  }

  public toSelectOption(mode: Mode) {
    return {
      id: mode.id.toString(),
      label: mode.name,
      value: mode.id,
    };
  }

  public get modes$(): Observable<Mode[]> {
    return this._modes$.asObservable();
  }
}

export interface EditModeBody {
  name: string;
}
